import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { motion } from "framer-motion";
import useD3 from "../../hooks/useD3";

const sortPages = (a, b) =>
  a.path.toLowerCase().localeCompare(b.path.toLowerCase());

const ForceGraph = ({ pages }) => {
  const [zoom, setZoom] = useState(10);
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [simulationState, setSimulationState] = useState([]);
  const [tooltip, setToolTip] = useState({
    x: 0,
    y: 0,
    content: "",
    showing: false,
  });
  const simulation = useRef();
  const container = useRef();
  const linksGroup = useRef();
  const nodesGroup = useRef();

  useEffect(() => {
    // process pages -> nodes
    setNodes(
      pages
        .sort(sortPages)
        .filter(p => p.parent || p.path === "/")
        .reduce((a, b) => {
          const { x, y, vx, vy } = simulationState.find(
            xy => xy.path === b.path
          ) ||
            simulationState.find(xy => xy.path === b.parent) || {
              x: 0,
              y: 0,
            };
          return [
            ...a,
            {
              vx,
              vy,
              x,
              y,
              ...b,
              id: b.path,
              level: b.path.split("/").filter(Boolean).length,
              // use old coordinates if saved
            },
          ];
        }, [])
    );

    // process pages -> links
    setLinks(
      pages
        .sort(sortPages)
        .filter(
          node =>
            node.path !== "/" &&
            node.parent &&
            pages.find(n => n.path === node.parent)
        )
        .reduce(
          (a, b) => [...a, { source: b.parent, target: b.path, weight: 1 }],
          []
        )
    );
  }, [pages]);

  // create the graph
  const graph = useD3(
    svg => {
      const width = container.current.clientWidth;
      const height = container.current.clientHeight;

      if (!simulation.current) {
        simulation.current = d3
          .forceSimulation(nodes)
          .force(
            "link",
            d3
              .forceLink(links)
              .id(d => d.id)
              .distance(d => (Math.max((4 - d.level) / 2) + 2, 1) * 25)
          )
          .force(
            "charge",
            d3
              .forceManyBody()
              .strength(d => (Math.max((6 - d.level) / 2), 1) * -10)
          )
          .force(
            "collision",
            d3
              .forceCollide()
              .radius(d => 3 * Math.max(5 - d.level, 1))
              .strength(0.75)
          );
        // .force("xForce", d3.forceX(1000).strength(0.005))
        // .force("yForce", d3.forceY(1000).strength(0.005))
        // .force("center", d3.forceCenter(width / 2, height / 2));
      } else {
        simulation.current.nodes(nodes);
        simulation.current
          .force(
            "link",
            d3
              .forceLink(links)
              .id(d => d.id)
              .distance(d => (Math.max((4 - d.level) / 2), 1) * 30)
          )
          .force(
            "charge",
            d3
              .forceManyBody()
              .strength(d => (Math.max((6 - d.level) / 2), 1) * -5)
          )
          .force(
            "collision",
            d3
              .forceCollide()
              .radius(d => 4 * Math.max(4 - d.level, 1))
              .strength(0.75)
          );
        // .force("center", d3.forceCenter(width / 2, height / 2));
        simulation.current.alpha(0.5).restart();
      }

      svg.attr("viewBox", [0, 0, width, height]);

      // links
      const link = svg.select("#links").selectAll("line").data(links);
      link.exit().remove();
      link
        .join("line")
        // .merge(link)
        .attr("stroke-width", d => Math.sqrt(d.value))
        .attr("class", "stroke-gray stroke-[0.666]");

      // nodes
      const node = svg.select("#nodes").selectAll("circle").data(nodes);
      node.exit().remove();
      node
        .join("circle")
        // .merge(node)
        .attr("r", d => 3 * (Math.max(4 - d.level, 2) / 2))
        .attr("class", d => {
          switch (d.status) {
            case "200":
              return "cursor-default fill-red stroke-black stroke-[2]";
            case "500":
              return "cursor-default fill-black stroke-black stroke-[2]";
            case "404":
              return "cursor-default fill-black stroke-gray stroke-[1]";
            default:
              return "cursor-default fill-gray stroke-black stroke-[2]";
          }
        })
        .on("mouseover", (event, d) => {
          setToolTip({ x: d.x, y: d.y, content: d.path, showing: true });
        })
        .on("mouseout", d => {
          setToolTip(s => ({ ...s, showing: false }));
        });

      simulation.current.on("tick", () => {
        if (nodes[0]) {
          nodes[0].fx = width / 2;
          nodes[0].fy = height / 2;
        }
        node.attr("cx", d => d.x).attr("cy", d => d.y);
        link
          .attr("x1", d => d.source.x)
          .attr("y1", d => d.source.y)
          .attr("x2", d => d.target.x)
          .attr("y2", d => d.target.y);
      });
    },
    [pages, nodes, links],
    svg => {
      const simData = svg.selectAll("circle").data();
      setSimulationState(simData);
    }
  );

  return (
    <motion.div className="absolute inset-0 z-0 overflow-visible w-full h-full">
      <motion.div
        ref={container}
        drag
        whileDrag={{ cursor: "grabbing" }}
        className="absolute cursor-grab inset-0 z-0 overflow-visible w-full h-full"
        animate={() => ({
          scale: zoom / 10,
        })}
      >
        <motion.div
          className="pointer-events-none absolute bottom-[100%] right=[100%] text-3xs bg-black border border-red p-1 z-10"
          animate={() => ({
            x: tooltip.x,
            y: tooltip.y,
            opacity: tooltip.showing ? 1 : 0,
          })}
        >
          {tooltip.content}
        </motion.div>
        <svg
          ref={graph}
          className="absolute cursor-grab inset-0 z-0 overflow-visible w-full h-full"
        >
          <g ref={linksGroup} id="links" />
          <g ref={nodesGroup} id="nodes" />
        </svg>
      </motion.div>
      <div className="absolute top-0 right-0 flex flex-col z-20">
        <button
          type="button"
          className="h-6 w-6 hover:bg-red"
          onClick={() => {
            setZoom(s => {
              if (s < 10) {
                return s + 0.5;
              }
              return s;
            });
          }}
        >
          +
        </button>
        <button
          type="button"
          className="h-6 w-6 hover:bg-red"
          onClick={() => {
            setZoom(s => {
              if (s > 0.5) {
                return s - 0.5;
              }
              return s;
            });
          }}
        >
          -
        </button>
      </div>
    </motion.div>
  );
};

export default ForceGraph;
