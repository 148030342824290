import React from "react";
import * as d3 from "d3";

const useD3 = (renderChartFn, dependencies, callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    renderChartFn(d3.select(ref.current));
    return () => {
      if (callback) {
        callback(d3.select(ref.current));
      }
    };
  }, dependencies);
  return ref;
};

export default useD3;
