/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useReducer, useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { Button } from "@atoms";
import ForceGraph from "../components/molecules/ForceGraph";

const downloadCSV = (data, sitename) => {
  const allBrokenLinks = Object.values(data)
    .filter(p => p.status === "404")
    .map(p => p.path.replace(/\/?$/, "/"));

  console.log(allBrokenLinks);
  const rows = [
    [
      "title",
      "path",
      "description",
      "image",
      "canonical_url",
      "status",
      "links",
      "extLinks",
      "linksCount",
      "intLinksCount",
      "extLinksCount",
      "brokenLinks",
    ],
    ...Object.values(data).map(p =>
      [
        p?.meta?.["og:title"] || p.title,
        p.path,
        p?.meta?.["og:description"] || p?.meta?.description,
        p?.meta?.["og:image"],
        p?.meta?.["og:url"],
        p.status || "PENDING",
        p?.links?.join(","),
        p?.extLinks?.join(","),
        p.linksCount,
        p.intLinksCount,
        p.extLinksCount,
        p?.links
          ?.filter(l => allBrokenLinks.includes(l.replace(/\/?$/, "/")))
          .join(","),
      ].map(c => `"${String(c).replace(/"+/g, '""')}"`)
    ),
  ];
  const csvContent = `${rows.map(e => e.join(",")).join("\n")}`.replace(
    /undefined/g,
    ""
  );
  const csv = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(csv);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${sitename}-${new Date().toISOString()}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const sortPages = (a, b) =>
  a.path.toLowerCase().localeCompare(b.path.toLowerCase());

const processPage = (status, content, page, site, redirected) => {
  const domain = new URL(site.url).hostname.replace("www.", "");
  const doc = document.createElement("html");
  doc.innerHTML = content;

  const path = new URL(page.url).pathname.replace(/\/?$/, "/");
  const segments = path.split("/").filter(Boolean);
  const segmentsCount = segments.length;
  const parent = `/${segments.slice(0, segmentsCount - 1).join("/")}`.replace(
    /\/?$/,
    "/"
  );

  if (status !== "200") {
    return {
      status,
      path,
      title: "",
      meta: {},
      links: [],
      parent,
      crawled: true,
    };
  }

  const urlStartRegexPattern = `^https?://(www.)?${domain}`;
  const urlStartRegex = new RegExp(urlStartRegexPattern);

  const title = document.getElementsByTagName("title")?.[0]?.textContent;
  const links = [...new Set(Array.from(doc.getElementsByTagName("a")))]
    .map(a => a.getAttribute("href"))
    .filter(a => a?.length && (a.match(/^\//) || a.match(urlStartRegex)))
    .map(a => (a.match(/^http/) ? new URL(a).pathname : a).split(/[?#]/)[0]);
  const extLinks = [...new Set(Array.from(doc.getElementsByTagName("a")))]
    .map(a => a.getAttribute("href"))
    .filter(a => a?.length && (a.match(/^http/) || !a.match(urlStartRegex)));
  const sitemap =
    site.url +
    // eslint-disable-next-line no-unsafe-optional-chaining
    Array.from(doc.getElementsByTagName("link"))
      .find(link => link.getAttribute("rel") === "sitemap")
      ?.getAttribute("href");
  const meta = Array.from(doc.getElementsByTagName("meta"))
    .filter(m => m.getAttribute("content") && !!m.name?.length)
    .reduce((a, b) => ({ ...a, [b.name]: b.getAttribute("content") }), {});

  return {
    status,
    path,
    title,
    meta,
    sitemap,
    links,
    extLinks,
    linksCount: links.length + extLinks.length,
    intLinksCount: links.length,
    extLinksCount: extLinks.length,
    parent,
    crawled: true,
  };
};

const addParents = links => {
  const linksAndParents = [];
  links.forEach(link => {
    const segments = link.split("/").filter(Boolean);
    segments.forEach((segment, i) => {
      const path = `/${segments.slice(0, segments.length - 1).join("/")}/`;
      if (!linksAndParents.includes(path) && path.length > 2) {
        linksAndParents.push(path.replace(/\/?$/, "/"));
      }
    });
    if (!linksAndParents.includes(link)) {
      linksAndParents.push(link.replace(/\/?$/, "/"));
    }
  });
  return linksAndParents.sort();
};

// crawl the site (this is a dummy func for now)
const crawl = async site => {
  const response = await fetch("/api/fetch-page", {
    method: "POST",
    body: site.nextCrawl.url,
  })
    .then(res => res.json())
    .then(res => res)
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log("fetch error:", e);
    });

  // get home page
  const page = processPage(
    response.status,
    response.content,
    site.nextCrawl,
    site,
    response.redirected
  );
  // eslint-disable-next-line no-promise-executor-return
  await new Promise(r => setTimeout(r, 500));

  return { ...page, redirected: true };
};

const reducer = (s, action) => {
  switch (action.type) {
    case "setPages":
      return {
        ...s,
        pages: action.pages,
        crawling: true,
        messages: [
          ...s.messages,
          `Crawling ${Object.values(action.pages)[0].url}`,
        ],
        nextCrawl: Object.values(action.pages)[0],
      };
    case "setSitemap":
      return { ...s, sitemap: action.url };
    case "setCrawl":
      return { ...s, currentCrawl: action.page };
    case "setSiteUrl":
      return { ...s, url: action.url };
    case "toggleVisualize":
      return { ...s, visualize: !s.visualize };
    case "togglePerformance":
      return { ...s, performance: !s.performance };
    case "addEmptyPages":
      // eslint-disable-next-line no-case-declarations
      const nLinksAndParents = addParents(action.pages);
      // eslint-disable-next-line no-case-declarations
      const nPages = nLinksAndParents.reduce((a, b) => {
        const segments = b.split("/").filter(Boolean);
        const segmentsCount = segments.length;
        const parent = `/${segments
          .slice(0, segmentsCount - 1)
          .join("/")}`.replace(/\/?$/, "/");
        return {
          ...a,
          [b]: {
            path: b,
            crawled: false,
            parent,
            url: (s.url + b).replace(/(?<!:)\/\//g, "/"),
          },
        };
      }, {});
      return { ...s, pages: { ...nPages, ...s.pages } };
    case "addRedirect":
      return {
        ...s,
        pages: {
          ...s.pages,
          [action.page.path]: {
            ...action.page,
            crawled: true,
            redirected: true,
            redirectUrl: action.url,
            status: "30*",
          },
        },
      };
    case "addPage":
      // eslint-disable-next-line no-case-declarations
      const linksAndParents = addParents(action.page.links);
      // eslint-disable-next-line no-case-declarations
      const emptyPages = linksAndParents.reduce((a, b) => {
        const segments = b.split("/").filter(Boolean);
        const segmentsCount = segments.length;
        const parent = `/${segments
          .slice(0, segmentsCount - 1)
          .join("/")}`.replace(/\/?$/, "/");
        return {
          ...a,
          [b]: {
            path: b,
            crawled: false,
            parent,
            url: (s.url + b).replace(/(?<!:)\/\//g, "/"),
          },
        };
      }, {});

      // eslint-disable-next-line no-case-declarations
      const pages = {
        // add links from page
        ...emptyPages,
        // currently crawled pages (overwrites links)
        ...s.pages,
        // newly crawled page
        [action.page.path]: action.page,
      };
      // eslint-disable-next-line no-case-declarations
      const newState = {
        ...s,
        currentCrawl: null,
        nextCrawl: null,
        crawledPages: Object.values(pages).filter(p => p.crawled).length,
        pages,
      };
      if (!newState.sitemap && action.page.sitemap) {
        newState.sitemap = action.page.sitemap;
      }
      // eslint-disable-next-line no-case-declarations
      const firstUncrawledPage = Object.values(newState.pages)
        .sort(sortPages)
        .find(p => !p.crawled);

      if (firstUncrawledPage) {
        return {
          ...newState,
          messages: [
            ...s.messages,
            `Crawling ${
              firstUncrawledPage.path === "/"
                ? firstUncrawledPage.url
                : firstUncrawledPage.path
            }`,
          ],
          nextCrawl: firstUncrawledPage,
        };
      }

      return { ...newState, crawling: false };
    default:
      throw new Error();
  }
};

const Voyager = () => {
  const [ticks, setTicks] = useState(0);
  // define site 0
  const [site0, updateSite0] = useReducer(reducer, {
    url: "https://designbycosmic.com",
    crawling: false,
    complete: false,
    performance: false,
    messages: [],
    pages: {},
    sitemap: null,
    currentCrawl: null,
    nextCrawl: null,
    crawledPages: 0,
    visualize: true,
  });

  // define site 1 (for comparison)
  // eslint-disable-next-line no-unused-vars
  const [site1, updateSite1] = useReducer(reducer, {
    url: "https://designbycosmic.com",
    crawling: false,
    complete: false,
    performance: false,
    messages: [],
    pages: {},
    sitemap: null,
    currentCrawl: null,
    nextCrawl: null,
    crawledPages: 0,
    visualize: true,
  });

  // ticks to check for crawls
  useEffect(() => {
    // crawl a page if there's a page to be crawled
    if (site0.nextCrawl && !site0.currentCrawl) {
      updateSite0({
        type: "setCrawl",
        page: site0.nextCrawl,
      });
      crawl(site0).then(page => {
        if (page.redirected) {
          updateSite0({
            type: "addRedirect",
            page: site0.nextCrawl,
            url: page.url,
          });
          updateSite0({ type: "addPage", page });
        } else {
          updateSite0({ type: "addPage", page });
        }
      });
    }

    // tock
    const tick = setTimeout(() => {
      // eslint-disable-next-line no-return-assign
      setTicks(s => (s += 1));
    }, 1e3);
    return () => {
      clearInterval(tick);
    };
  }, [ticks]);

  const pages = useMemo(
    () => Object.values(site0.pages),
    // eslint-disable-next-line no-unsafe-optional-chaining
    [site0.crawledPages]
  );

  useEffect(() => {
    fetch("/api/fetch-page", {
      method: "POST",
      body: site0.sitemap || `${site0.url}/sitemap.xml`,
    })
      .then(res => res.json())
      .then(res => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.content, "text/xml");
        const emptyPages = [
          ...new Set(
            Array.from(xmlDoc.getElementsByTagName("loc")).map(
              l => new URL(l.innerHTML).pathname
            )
          ),
        ];
        updateSite0({ type: "addEmptyPages", pages: emptyPages });
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [site0.sitemap, site0.crawling]);

  // save data
  useEffect(() => {
    window.localStorage.setItem("site0", JSON.stringify(site0));
  }, [site0.nextCrawl]);

  // placeholder
  return (
    <div className="bg-black text-white h-full max-h-screen w-full p-3 gap-3">
      {/* site0 */}
      <div
        className={classNames("flex flex-col border border-red w-full h-full")}
      >
        {/* site info */}
        <div className="flex gap-3 flex-shrink-0 p-3 border-b border-red text-xs w-full items-center">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
            xmlSpace="preserve"
            className="w-12 block"
          >
            <path
              className="fill-white"
              d="M524.8,564.9c16.2,0,29.3-13.1,29.3-29.3c0-16.2-13.1-29.3-29.3-29.3c-16.2,0-29.3,13.1-29.3,29.3
	C495.5,551.7,508.6,564.9,524.8,564.9z M524.8,525.8c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8c-5.4,0-9.8-4.4-9.8-9.8
	C515,530.2,519.4,525.8,524.8,525.8z M410.6,608.3c-3.8-3.8-10-3.8-13.8,0l-76,76l0,0L148,857.1c-1.8,1.8-2.8,4.3-2.8,6.9
	c0,2.6,1,5,2.8,6.8l41.5,41.5c3.8,3.8,10,3.8,13.8,0l69.1-69.1l0,0l179.7-179.7c1.8-1.8,2.8-4.3,2.8-6.9c0-2.6-1-5-2.8-6.8
	l-13.9-13.9l18.2-17.8c39.8,33.1,97.6,33.1,137.4,0l285.7,286c3.8,3.8,10,3.8,13.9,0s3.8-10,0-13.9L607.4,604
	c12-14.4,19.9-31.6,23.2-50l52.6-52.2c3.8-3.8,3.9-10,0.1-13.8c0,0,0,0-0.1-0.1L653.8,459l18.8-18.3c3.8-3.8,3.9-10,0.1-13.8
	c0,0,0,0-0.1-0.1l-39.1-39.1c-3.8-3.8-10-3.9-13.8-0.1c0,0,0,0-0.1,0.1l-18.3,18.4l-28.8-29.3c-3.8-3.8-10-3.9-13.8-0.1
	c0,0,0,0-0.1,0.1l-52.5,52.6c-18.4,3.4-35.5,11.5-49.7,23.6L175,171.9c-3.8-3.8-10-3.9-13.8-0.1c0,0,0,0-0.1,0.1
	c-3.8,3.8-3.9,10-0.1,13.8c0,0,0,0,0.1,0.1l281.1,281.4c-33.1,39.8-33.1,97.6,0,137.4l-17.8,17.9L410.6,608.3z M626.6,408.5
	l25.3,25.3l-11.4,11.4l-25.3-25.3L626.6,408.5z M565.6,397.9l96.9,96.9l-30.6,31c-4.5-51.8-45.5-93-97.2-97.7L565.6,397.9z
	 M524.8,447.6c48.6,0,88,39.4,88,88c0,48.6-39.4,88-88,88c-48.6,0-88-39.4-88-88C436.9,487,476.2,447.6,524.8,447.6z M369.1,718.9
	l-27.7-27.7l20.7-20.7l27.7,27.7L369.1,718.9z M334.6,753.4L307,725.7l20.7-20.7l27.7,27.7L334.6,753.4z M300,788l-27.7-27.7
	l20.7-20.7l27.7,27.7L300,788z M265.4,822.5l-27.6-27.6l20.7-20.8l27.7,27.7L265.4,822.5z M230.9,857.1l-27.7-27.7l20.7-20.7
	l27.2,27.7L230.9,857.1z M196.3,891.7L168.7,864l20.8-20.8l27.6,27.7L196.3,891.7z M403.7,684.3l-27.7-27.7l27.7-27.7l27.7,27.7
	L403.7,684.3z"
            />
            <path
              className="fill-red"
              d="M832.6,109h-6.4c-11.9,60.3-59.6,107.9-120.1,119.8v6.4c60.5,11.9,108.2,59.4,120.1,119.8h6.4
	c11.9-60.3,59.6-107.9,120.1-119.8v-6.4C892.2,216.9,844.6,169.3,832.6,109z"
            />
          </svg>
          {/* url */}
          <div className="flex items-center justify-center flex-grow">
            <label className="mr-2">url:</label>
            <input
              name="url"
              onChange={e => {
                updateSite0({ type: "setSiteUrl", url: e.target.value });
              }}
              value={site0.url}
              className="w-full border-b border-red border-opacity-30 bg-transparent py-1 leading-0 px-0 focus:border-opacity-100"
            />
          </div>
          <label
            onClick={() => {
              updateSite0({ type: "toggleVisualize" });
            }}
            className="flex items-center cursor-pointer"
          >
            <div
              className={classNames(
                "h-4 w-4 flex items-center justify-center border-red border mr-2 transition duration-200",
                {
                  "bg-red text-white": site0.visualize,
                  "text-transparent": !site0.visualize,
                }
              )}
            >
              X
            </div>
            Visualize
          </label>
          <label
            onClick={() => {
              if (!site0.crawling) {
                updateSite0({ type: "togglePerformance" });
              }
            }}
            className="flex opacity-50 pointer-events-none items-center cursor-pointer"
          >
            <div
              className={classNames(
                "h-4 w-4 flex items-center justify-center border-red border mr-2 transition duration-200",
                {
                  "bg-red text-white": site0.performance,
                  "text-transparent": !site0.performance,
                }
              )}
            >
              X
            </div>
            Measure Performance
          </label>
          <div
            className={classNames("ml-auto", {
              "pointer-events-none opacity-50 ": site0.crawling,
            })}
          >
            <Button
              color="red"
              onClick={() =>
                updateSite0({
                  type: "setPages",
                  pages: {
                    "/": {
                      path: "/",
                      crawled: false,
                      url: site0.url,
                    },
                  },
                })
              }
              size="xxs"
            >
              {site0.crawling ? "crawling" : "crawl"}
            </Button>
          </div>
        </div>
        {/* chart */}
        {site0.visualize && (
          <div
            className={classNames(
              "flex items-center justify-center flex-shrink-0 h-[66.6vh] relative overflow-hidden border-b border-red border-solid"
            )}
          >
            <ForceGraph pages={pages} />
            {/* messages */}
            <div className="absolute text-gray text-3xs bottom-0 left-0 p-3 z-10">
              <ul>
                {site0.messages
                  .slice(site0.messages.length - 3, site0.messages.length)
                  .map(m => (
                    <li key={m} className="mt-1">
                      {m}
                    </li>
                  ))}
              </ul>
            </div>
            {/* stats */}
            <ul className="absolute text-gray text-3xs bottom-0 right-0 p-3 z-10">
              <li className="mt-1">
                {
                  Object.values(site0.pages).filter(p => p.status === "200")
                    .length
                }{" "}
                200 OK
              </li>
              <li className="mt-1">
                {
                  Object.values(site0.pages).filter(p => p.status === "30*")
                    .length
                }{" "}
                30* Redirected
              </li>
              <li className="mt-1">
                {
                  Object.values(site0.pages).filter(p => p.status === "404")
                    .length
                }{" "}
                404 Not Found
              </li>
              <li className="mt-1">{site0.crawledPages} Crawled</li>
              <li className="mt-1">
                {Object.values(site0.pages).length} Discovered
              </li>
            </ul>
          </div>
        )}
        {/* table */}
        <ul className="overflow-auto text-3xs uppercase flex-grow flex flex-col">
          <li
            className={classNames(
              "sticky bg-black top-0 left-0 right-0 p-2 w-full font-bold border-b border-red border-opacity-60 grid grid-cols-5 gap-3"
            )}
          >
            <span className="flex col-span-2 items-center">path</span>
            <span className="flex items-center">parent</span>
            <span className="flex items-center">status</span>
            <button
              type="button"
              className="flex cursor-pointer text-red hover:text-white transition duration-200 items-center justify-end"
              onClick={() =>
                downloadCSV(site0.pages, new URL(site0.url).hostname)
              }
            >
              <span className="mr-1">Download CSV</span>
              {/* download code icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-3 w-4"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="42"
                  d="M160 368L32 256l128-112M352 368l128-112-128-112M192 288.1l64 63.9 64-63.9M256 160v176.03"
                />
              </svg>
            </button>
          </li>
          {Object.values(site0.pages)
            .sort(sortPages)
            .map((page, i) => (
              <li
                key={page.path}
                className={classNames(
                  "p-2 w-full border-b border-red border-opacity-30 grid grid-cols-5 gap-3"
                )}
              >
                <span className="col-span-2 overflow-hidden">{page.path}</span>
                <span className="overflow-hidden">{page.parent}</span>
                <span
                  className={classNames({
                    "animate-pulse text-red":
                      !page.status && page.path === site0.currentCrawl?.path,
                  })}
                >
                  {page.status && page.status}
                  {!page.status &&
                    page.path === site0.currentCrawl?.path &&
                    "crawling"}
                  {!page.status &&
                    page.path !== site0.currentCrawl?.path &&
                    "pending"}
                </span>
                <span className="flex justify-end">
                  <a href={page.url} className="text-red">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </a>
                </span>
              </li>
            ))}
        </ul>
      </div>
      {/* site1 */}
      {!!site1.url.length && <div />}
    </div>
  );
};

export default Voyager;
